import { GridItem } from '@chakra-ui/layout';
import { EditableArea, EditorContextHelper } from '@magnolia/react-editor';
import { useEffect, useState } from 'react';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { DEFAULT_EMPTY_TEXT, IMAGE_DOMAINS } from '~/constants';
import { MagnoliaImage, Metadata } from '~/types/Magnolia';
import Container from '@components/LayoutStructure/Container';
import { EditableAreaType } from '~/types/Magnolia';
import BaseBackground from '@components/Background/BaseBackground';
import { OverlayColors } from '~/types/OverlayColors';
import { useBreakpointValue } from '@chakra-ui/react';

interface ColumnProps {
  columns: string;
  column1: EditableAreaType;
  column2: EditableAreaType;
  column3: EditableAreaType;
  columnLayout: string;
  fullWidth: boolean;
  noHorizontalPadding: boolean;
  noHorizontalPaddingViewports?: ('Mobile' | 'Tablet' | 'Desktop')[];
  noVerticalPadding: boolean;
  isFlex: boolean;
  switchable: SwitchableProps;
  metadata: Metadata;
  maxWidthContainer: string;
}
interface SwitchableProps {
  image?: MagnoliaImage;
  imageUrl?: string;
  bgImageOverlay: OverlayColors;
  bgOverlayOpacity?: string;
  bgImagePosition?: string;
  bgBorderRadius?: string;
  bgColor?: string;
  bgColorRange?: string;
  field?: 'bgColorForm' | 'bgImageForm' | 'bgExternalImageForm';
  textColor?: 'default' | 'light' | 'dark';
  maxWidthContainer?: 'none' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}

function getTextColor(field, bgImageOverlay, switchableTextColor) {
  return field === 'bgImageForm'
    ? bgImageOverlay === 'dark'
      ? 'gray.50'
      : 'gray.800'
    : field === 'bgColorForm'
    ? switchableTextColor === 'dark'
      ? 'gray.800'
      : 'gray.50'
    : '';
}

function checkNodes(column) {
  const ignoreList = ['column1', 'column2', 'column3', 'expanderItems'];
  return (
    column &&
    column['@nodes'].every((node) => {
      if (column[node]['@nodes'] && column[node]['@nodes'].length > 0) {
        return checkNodes(column[node]);
      }
      switch (column[node]['mgnl:template']) {
        case 'spa-lm:components/heading':
          if (!column[node].title) {
            return true;
          }
          break;
        case 'spa-lm:components/image':
          if (
            column[node].switchable.field === 'damChooser' &&
            !column[node].switchable.image
          ) {
            return true;
          }
          if (
            column[node].switchable.field === 'externalSource' &&
            !IMAGE_DOMAINS.some((url) =>
              column[node].switchable.imageUrl.includes(url)
            )
          ) {
            return true;
          }
          break;
        case 'spa-lm:components/paragraph':
          if (!column[node].text || column[node].text === DEFAULT_EMPTY_TEXT) {
            return true;
          }
          break;
        case 'spa-lm:components/expander':
          if (
            column[node].expanderItems &&
            column[node].expanderItems['@nodes'].length > 0
          ) {
            return checkNodes(column[node].expanderItems);
          }
          return true;

        case 'spa-lm:components/list':
          return checkNodes(column[node].items);
        case 'spa-lm:components/listItem':
          if (!column[node].text) {
            return true;
          }
          break;
        case 'spa-lm:components/columns':
          return [...Array(parseInt(column[node].columns))].map((_, i) =>
            column[node]['column' + (i + 1)]
              ? checkNodes(column[node]['column' + (i + 1)])
              : true
          );
        case 'spa-lm:components/barChart':
          if (!column[node].chartData) {
            return true;
          }
          break;
        case 'spa-lm:components/sectionImage':
          if (
            !column[node].image ||
            (!column[node].imageAlt && !column[node].imageUrl)
          ) {
            return true;
          }
          break;
        case 'spa-lm:components/sectionChart':
          if (!column[node].chartData) {
            return true;
          }
          break;
        case 'spa-lm:components/sectionTable':
          if (!column[node].tableData) {
            return true;
          }
          break;

        case 'spa-lm:components/sectionTitle':
          if (!column[node].text || column[node].text === DEFAULT_EMPTY_TEXT) {
            return true;
          }
          break;
        case 'spa-lm:components/sectionFAQ':
          if (
            column[node].switchable.field === 'dataCatalog' &&
            !column[node].switchable.dataFAQ
          ) {
            return true;
          }
          if (
            column[node].switchable.field === 'manually' &&
            column[node].switchable.faqs['@nodes'].length === 0
          ) {
            return true;
          }
          break;
        case 'spa-lm:components/stats':
          if (column[node].stats['@nodes'].length === 0) {
            return true;
          }
          break;
        case 'spa-lm:components/interlinkingModule':
          if (!column[node].jsonData) {
            return true;
          }
          break;
        default:
          if (ignoreList.includes(column[node]['@name'])) {
            return true;
          }
          return false;
      }
    })
  );
}
function columnSpacing(index, columnLayout) {
  switch (columnLayout) {
    case 'left':
      if (index === 0) {
        return 2;
      }
      break;
    case 'right':
      if (index === 1) {
        return 2;
      }
      break;
    case 'full':
      return 3;
    default:
      return 1;
  }
}

const ColumnsComponent = (props: ColumnProps) => {
  const [hideContainer, setHideContainer] = useState(false);
  const [inEditor, setInEditor] = useState(false);

  const {
    columns = '1',
    columnLayout = 'even',
    fullWidth = false,
    switchable,
    isFlex = false,
    noHorizontalPadding = false,
    noHorizontalPaddingViewports = ['Mobile', 'Tablet', 'Desktop'],
    noVerticalPadding = false,
    metadata,
    maxWidthContainer = 'xl',
  } = props;
  const {
    bgImageOverlay = 'default',
    bgOverlayOpacity = '0',
    field,
    bgImagePosition = 'center',
    bgBorderRadius = '0',
    textColor = 'dark',
    bgColor = 'secondary',
    bgColorRange = '50',
    image,
    imageUrl = '',
  } = switchable;
  const maxWidth =
    fullWidth || maxWidthContainer === 'none'
      ? 'full'
      : `container.${maxWidthContainer}`;
  const className = fullWidth ? 'FullWidthSection' : '';
  const txtColor = getTextColor(field, bgImageOverlay, textColor);
  const columnCount = parseInt(columns);

  useEffect(() => {
    EditorContextHelper.inEditorAsync().then((inEditor) => {
      setInEditor(inEditor);
    });
  }, []);

  useEffect(() => {
    const isEmpty = [...Array(columnCount)].map((_, i) => {
      return checkNodes(props['column' + (i + 1)]);
    });
    setHideContainer(!isEmpty.includes(false));
  }, [columnCount, props]);

  const noHorizontalPaddingForViewport = useBreakpointValue({
    base:
      noHorizontalPadding && noHorizontalPaddingViewports.includes('Mobile'),
    md: noHorizontalPadding && noHorizontalPaddingViewports.includes('Tablet'),
    lg: noHorizontalPadding && noHorizontalPaddingViewports.includes('Desktop'),
  });

  if (hideContainer && !inEditor) return <></>;

  return (
    <Container
      maxW={maxWidth}
      className={`${className} column-container`}
      textColor={txtColor}
      noHorizontalPadding={noHorizontalPaddingForViewport}
      m="auto"
    >
      <BaseBackground
        pt={noVerticalPadding ? 0 : 8}
        display={{ lg: 'grid', base: 'flex' }}
        flexDirection="column"
        gridGap={4}
        gridTemplateColumns={`repeat(${
          columnLayout === 'even' ? columnCount : 3
        }, 1fr)`}
        borderRadius={bgBorderRadius}
        position="relative"
        // TODO: remove this when we have a better solution for nesting components using hydration-on-demand
        sx={{
          section: {
            flex: { md: '1 1 0px', base: '1 1 100%' },
          },
          // To preserv the border radius of the background if set
          overflowY: noVerticalPadding ? 'hidden' : 'initial',
          overflowX: noHorizontalPaddingForViewport ? 'hidden' : 'initial',
        }}
        type={field}
        bgImageOverlay={bgImageOverlay}
        backgroundPosition={bgImagePosition}
        image={image}
        imageUrl={imageUrl}
        bgOverlayOpacity={bgOverlayOpacity}
        bgColor={bgColor}
        bgColorRange={bgColorRange}
      >
        {[...Array(columnCount)].map((x, i) =>
          props['column' + (i + 1)] ? (
            <GridItem
              key={metadata['@id'] + i}
              colSpan={columnSpacing(i, columnLayout)}
            >
              <EditableArea
                content={props['column' + (i + 1)]}
                parentTemplateId={metadata['mgnl:template']}
                className={isFlex ? 'flex-columns' : ''}
              />
            </GridItem>
          ) : (
            ''
          )
        )}
      </BaseBackground>
    </Container>
  );
};

const Columns: React.FC<ColumnProps> = withHydrationOnDemand({
  on: ['idle', 'visible'],
  initialVisible: true,
})(ColumnsComponent);

export default Columns;
